
/*
Author: bootstrapthemes
Author URI: http://bootstrapthemes.co
*/


/*==========================================
 1.  PREDIFINEED (FROM 1 TO 457)
 2.  NAVIGATION - LINE NO 465 
 3.  HOME - LINE NO 517
 4.  ABOUT US - LINE NO 563
 5.  TOBIAS SECTION - LINE NO 615
 5.  SERVICE - LINE NO 640
 6.  TEAM - LINE NO 682
 7.  PORTFOLIO - LINE NO 715
 8.  CONTACT US - LINE NO 736
 9.  FOOTER - LINE NO 761
==========================================*/

/*==========================================

 1.   BOOTSTRAP FRAMEWORK
 2.   MATERIAL DESIGN
 3.   NAVMENU
 4.   WOW JS 
 5.   ANIMATE CSS
 6.   MAGNIFIC-POPUP PORTFOLIO  
 7.   MAGNIFIC-POPUP VIDEO
 8.   GOOGLE GMAPS
 9.   JQUERY EASING JS
 10.  FONT AWESOME
 11.  MONTSERRAT BOLD

==========================================*/



/* ==========================================================================
   Author's custom styles
   ========================================================================== */

html,
body {
    background-color: #fff;
    font-size: 14px;
    color: #848484;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    font-family: 'montserratbold';
    font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
}
/*==========================================
PRE LOADER 
==========================================*/

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fefefe;
    z-index: 99999;
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}
.preloader h2 {
    font-size: 3rem;
    text-align: center;
    margin-top: 200px;
    margin-bottom: 50px;
}
.loaded {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}
.main_figur {
    width: 100%;
    overflow: hidden;
}
.row{
    margin-bottom:0;
}
img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}
a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}
a,
button,
a span {
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.btn.extra-width {
    padding: 13px 40px;
}
.btn:focus,
.btn:active {
    outline: inherit;
}
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*    direction: rtl;*/
}
.clear {
    clear: both;
}
img {
    max-width: 100%;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    outline: none;
}
.form-control {
    border: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 1rem;
    height: 45px;
}
.form-control {
    display: inline-block;
    width: 100%;
    height: 80px;
    padding: 30px 15px;
    font-size: 14px;
    text-align: center;
    line-height: 1.42857143;
    color: #fff;
    background-color: #1c1c1c;
    background-image: none;
    border: 0px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-bottom: 1px solid #333;
}

.form-control:focus {
    color: #fff;
    border-bottom: 1px solid #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
}
/*.main_contactus_content .form-control:focus {
    border-color: #43afb7;
}*/
input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], 
input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], 
input[type=search], textarea.materialize-textarea {
    background-color: #1a1a1a;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    height: 5rem;
    width: 100%;
    margin: 0 0 15px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
    text-align: center;
}
h1,
h2 {
    font-weight: 300;
    margin: 0;
    line-height: 2rem;
}
h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    color: #fff;
}
h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
h3 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin-bottom: 30px;
}
h4 {
    font-size: 1.125rem;
    line-height: 2rem;
}

p {
    line-height: 1.375rem;
    margin: 0 0 15px;
}
p:last-child {
    margin-bottom: 0px;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.icon-right {
    float: right;
    color: #fff;
}

.text {
    color: #333333;
}
.colorstext {
    color: #fff;
}
.colorstext2 {
    color: #666666;
}

.colorsbg {
    background: #000;
}
.lightbg {
    background-color: #1a1a1a;
}
.transition {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

/*=============================================================
    Sections & Overlay Style
===============================================================*/

.sections {
    padding-top: 85px;
    padding-bottom: 85px;
}

.overlay {
    background-color: rgba(30, 73, 107, 0.2);
    width: 100%;
    height: 650px;
}
.video_overlay {
    background-color: rgba(30, 73, 107, 0.2);
    width: 100%;
    height: auto;
    padding: 270px 20px 300px 20px;
}

/*==============================================
        Separator
================================================*/

.separator {
    height: 1px;
    width: 60px;
    margin: 10px 0;
    background-color: #333;
}
.whiseparator {
    height: 2px;
    width: 40px;
    margin: 14px auto 10px auto;
    background: #ffffff;
}

/*section:nth-of-type(odd) {
    background-color: #F9F9F9;
}*/
/*================================================
        Button
===================================================*/

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary.disabled:hover {
    color: #fff;
    background-color: #1470a5;
    border-color: transparent;
}
.btn {
    display: inline-block;
    padding: .4em;
    text-align: center;
    color: #fff;
    letter-spacing: 1px;
    margin: 0;
    border-radius: 0px;
    background-color: transparent;
    transition: .5s;
    border: 0px;
    font-size:1rem;
    height: 50px;
}
.btn:hover {
    background-color: #000;
    color: #fff;
    text-decoration: none;
}
.btn-primary {
    color: #fff;
    background-color: #333;
    transition: .5s;
    padding: 1rem 2rem;
    text-transform: uppercase;
    font-size: 1.125rem;
    border:1px solid #ddd;
    display: inline-block;
}
.btn-primary:hover {
    color: #fff;
    background-color: #222;
    border:1px solid #ddd;
}
.btn-default {
    border: 3px solid #fff;
    padding: 1rem 2rem;
}
.btn-larg{
    color: #fff;
    background-color: #000;
    width: 185px;
    padding: 1rem 1rem;
    text-transform: uppercase;
    text-align: center;
}
.btn-larg:hover{
    background-color: #333;
}

/*===============================================================
Extra height css
=================================================================*/

.margin-left-15 {
    margin-left: 15px;
}
.margin-right-15 {
    margin-right: 15px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}
.margin-top-35 {
    margin-top: 35px;
}
.margin-top-40 {
    margin-top: 40px !important;
}
.margin-top-110 {
    margin-top: 110px;
}
.margin-top-120 {
    margin-top: 120px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}
.margin-bottom-40 {
    margin-bottom: 40px;
}
.margin-top-60 {
    margin-top: 60px;
}
.margin-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}
.margin-bottom-60 {
    margin-bottom: 60px;
}
.margin-top-80 {
    margin-top: 80px;
}
.margin-bottom-80 {
    margin-bottom: 80px;
}
.padding-top-20 {
    padding-top: 1.33rem;
}
.padding-bottom-20 {
    padding-bottom: 1.33rem;
}
.padding-top-40 {
    padding-top: 2.66rem;
}
.padding-bottom-40 {
    padding-bottom: 2.66rem;
}
.padding-top-60 {
    padding-top: 5rem;
}
.padding-bottom-60 {
    padding-bottom: 5rem;
}
.padding-bottom-80{
    padding-bottom: 5rem;
}
.padding-top-80 {
    padding-top: 80px;
}
.padding-top-100 {
    padding-top: 100px;
}
.padding-bottom-0 {
    padding-bottom: 0;
}

.transparentbg{
    background-color: transparent !important; 
}

/*==============================================================
            Navigation Style
================================================================*/

.cd-header a.brand-logo img{
    width: 5%;
    margin-left: 10%;
    padding: 5px;
    margin-top: 6px;
}
.cd-header{
    position: fixed;
    top: 0;
}
.cd-header.small{
    background: #333;
}
nav ul li {
    transition: background-color .3s;
    float: none;
    padding: 0;
    text-align: center;
}
#main-nav li {
    height: 15%;
}

#main-nav li a {
    position: relative;
    display: block;
    padding: 1.2% 10%;
    height: 100%;
    border-bottom: 1px solid #dfdbec;
    color: #3a393f;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: bold;
}
.brand-logo img{
    width:50%;
}

/*==============================================================
            Head Title
================================================================*/
.head_title h2{
    text-transform: uppercase;
    color:#fff;
    line-height: 13rem;
}


/*=============================================================
                    home section
===============================================================*/

.home {
    /* background: url(../assets/images/homebg.png); */
    background-size: cover;
    position: relative;
    padding-top: 80px;

}

.main_home {
    /* padding: 25% 20px 170px 20px; */
    position: relative;
}

.main_home p{
    width:45%;
    margin:40px auto;
}


.home_socail_bar{
    background: url(../assets/images/bSidebg.png ) no-repeat scroll 0 0;
    width: 190px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 35%;
    text-align: right;
}
.home_socail_bar ul{
    position: absolute;
    right:30%;
    top:10%;
}
.home_socail_bar ul li a{
    color:#fff;
}

/*====================================================================
                 About Section 
======================================================================*/
.main_about_area .head_title{
    background: url(../assets/images/headingbg.png) no-repeat top center;
    width:323px;
    height: 220px;
    margin: 0 auto;
    text-align: center;
}

.main_about_area .single_about{
    padding-left: 20px;
}
.main_about_area .single_about_right_img{
    border: 10px solid #fff;
    padding: 20px 0px 20px 20px;
    margin-right: -20%;
}
.single_abt.single_about p.text-uppercase{
    line-height: 2rem;
    font-weight: 700;
}

.about_bottom{
    background: #1a1a1a;
    overflow: hidden;
}
.about_bottom .bottom_single_about.bottom_single_about_left_img{
    border: 3px solid #fff;
    width: 100%;
}
.about_bottom .bottom_single_about.bottom_single_about_left_img img{
    width: 100%;
}
.about_bottom .bottom_single_about.s_bootom_right_area{
    border: 3px solid #fff;
    height: auto;
    width: 100%;
}
.about_bottom .bottom_single_about.s_bootom_right_area img{
    display: inline-block;
    width: 51%;
}
.about_bottom .bottom_single_about .s_bottom_ab_text{
    display: inline-block;
    width: 45%;
}
.about_bottom .bottom_single_about .s_bottom_ab_text img{
    width:50px;
    height: 50px;
}
/*=======================================================================
Tobias section
=========================================================================*/
.tobias{
    background: url(../assets/images/tobiasbg.png) no-repeat right top;
    background-size: cover;
}
.main_tobias_area{
    overflow: hidden;
}
.main_tobias_area .single_tobias{}
.main_tobias_area .single_tobias.single_tobias_img{
    border:10px solid #fff;
    padding: 60px;
}

.main_tobias_area .single_tobias.single_tobias_text{}
.main_tobias_area .single_tobias.single_tobias_text h4{
    font-weight: 700;
}
.main_tobias_area .single_tobias.single_tobias_text h3{
    color:#fff;
    margin-bottom: 0px;
}

/*=================================================================
                    Service Section
===================================================================*/
.service{
    background: url(../assets/images/servicebg.jpg) no-repeat center center;
    background-size: cover;
}
.main_service_area .head_title{
    background: url(../assets/images/headingbg2.png) no-repeat top center;
    width:45%;
    margin: 10px auto;
    text-align: center;
    padding:90px 5px;
}
.main_service_area .head_title h2{
    line-height: 6rem;
}
.main_service_area{
    overflow: hidden;
}
.main_service_area .single_service{
    border:2px solid #fff;
    padding: 40px;
    position: relative;
}
.main_service_area .single_service .service_btn{
    position: absolute;
    left:25%;
    bottom:-1%;

}
.main_service_area .single_service .single_service_img{
    border:2px solid #fff;
    padding: 20px;
}
.service_more_btn{
    display: inline-block;

}


/*=====================================================================
                   Team Section
====================================================================*/

.main_team_area{
    overflow: hidden;
}
.main_team_area .main_team_content{
    margin-bottom:80px;
    display: inline-block;
    transition: 0.6s;

}

.main_team_content .col-sm-4:hover .single_team{
    transform:scale(1.02);
    transition: 0.6s;
}


.main_team_area .single_team img{
    width:100%;

}
.main_team_area .single_team .single_team_text{
    background: #1a1a1a;
    padding: 20px;
}
.main_team_area .single_team .single_team_text h4{
    color:#fff;
    text-transform: uppercase;
}


/*=====================================================================
                    portfolio section 
====================================================================*/
.maint_portfolio_area{
}
.maint_portfolio_area .head_title{
    width:45%;
}
.maint_portfolio_area .head_title h2{
    line-height: 4rem;
}
.main_portfolio_content{
    display: inline-block
}
.main_portfolio_content .single_portfolio img{
    width:100%;
}




/*=====================================================================
                    Contact Us Section
====================================================================*/
.main_contactus_area input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
    width: 100%;
    background: #1c1c1c;
}

.ourmap{
    height: 373px;
    width:100%;
}

.main_contactus_area .contact_us_right{}
.main_contactus_area .contact_us_right .single_map_bottom{
    background: #1c1c1c;
    padding: 10px;
}
.main_contactus_area .contact_us_right .single_map_bottom p{
    color:#fff;
}
.main_contactus_area .contact_us_right .single_map_bottom span{
    display: block;

}


/*=====================================================================
                   Footer Section
====================================================================*/
.footer{
    background: #1c1c1c;
}
.main_footer{
    overflow: hidden;
    display: inline-block;
    width:100%;
    padding: 40px 0px;
}
.main_footer .footer_logo a img{
    width:35%;
}
.main_footer .copyright_text{
    text-align: center;
}
.main_footer .copyright_text a{
    padding: 0px 5px;
    color:#fff;
}
.main_footer .copyright_text i{
    padding: 0px 5px;
    color:#ff0000;
}
.main_footer ul li a{
    color:#fff;
    transition: all 0.6s;
    
}
.footer_socail ul li a i{
    padding: 1px;
}
.footer_socail ul li a:hover i{
    color:#000;
}


/*=====================================================================
                    scrollup 
====================================================================*/

.scrollup{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    opacity: .3;
    position: fixed;
    bottom: 20px;
    right: 25px;
    color: #fff;
    cursor: pointer;
    background-color: #000;
    z-index: 1000;
    transition: opacity .5s, background-color .5s;
    -moz-transition: opacity .5s, background-color .5s;
    -webkit-transition: opacity .5s, background-color .5s;
}

.scrollup:hover {
    background: #fff;
    opacity: 1;
}

.scrollup i {
    font-size: 13px;
    position: absolute;
    opacity: 1;
    color: #333;
    left: 50%;
    top: 50%;
    margin-top: -7px;
    margin-left: -6px;
    text-decoration: none;

}


