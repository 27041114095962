

/*
Author: XpeedStudio
Author URI: http://www.Xpeedstudio.com
*/

/* Portrait tablets and medium desktops */
@media (max-width: 1199px) {
    .btn-larg{
        width:150px;
    }


}

/* Portrait tablets and small desktops */
@media (max-width: 991px) {
    html {
        font-size: 90%;
    }
    .list-inline>li {
        display: inline-block;
        padding-right: 3px;
        padding-left: 3px;
    }
    .main_service_area .single_service .service_btn {
        left: 15%;
    }

}
/* Landscape phones and portrait tablets */
@media  (max-width: 768px) {
    html {
        font-size: 90%;
    }
    .cd-header a.brand-logo img {
        width: 10%;
    }
    #main-nav li a{
        padding: 2% 10%;
    }
    .single_abt{
        margin-bottom: 40px;
        text-align: center;
    }
    .main_team_area .single_team{
        text-align: center;
    }
    .main_portfolio_content .single_portfolio.single_portfolio_1st{
        margin-bottom:30px;
    }
    .main_service_area .single_service .service_btn {
        left: 35%;
    }
    .single_map_bottom {
        text-align: center;
    }
    .main_footer .footer_logo{
        width: 50%;
        margin:40px auto;
        text-align: center;
    }
    .main_footer .footer_socail{
        text-align: center;
    }
}


/* Landscape phones and smaller */
@media (max-width: 479px) {
    html {
        font-size: 85%;
    }


}




@media (max-width: 320px) {

    html {
        font-size: 70.5%;
    }

}







